<template>
  <screen-grid :full-height="true">
    <screen-card :full-height="true">
      <template v-slot:body>
        <ui-data-search
          :element-fixed="elementFixed"
          :scroll-direction="scrollDirection"
          :is-mobile="isMobile"
          :is-tablet="isTablet"
          :is-loading="isLoading"
          :is-scrolling="isScrollingCampaign"
          view="campaign"
          ref="fixedElement"
          :with-filter="true"
          @search="onSearch"
        >
          <template v-slot:filterSlot>
            <user-express-list-filters
              @onUpdateFilters="onUpdateFilters"
              @onUpdateUserExpressFilter="onSearch"
              :user-express-filtered-active="userExpressFilteredActive"
              :user-express-filtered-deactivate="userExpressFilteredDeactivate"
              :user-express="userExpress"
            />
          </template>
        </ui-data-search>
        <div class="campaign-list__list">
          <ui-data-list
            :items="campaignMobile"
            :is-loading="isLoading"
            :is-scrolling="isScrollingCampaign"
            :no-more-data="noMoreDataCampaign"
            :pagination="campaignPagination"
            infinite-scroll
            @loadItems="
              loadCampaign(
                { filters: stringifiedSearchFilters, ...$event, page: campaignPagination.page + 1 },
                false,
                true
              )
            "
            @click="cellClicked({ row: { id: $event.id } })"
          >
            <template v-slot:skeleton>
              <div class="campaign-list__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
              <skeleton-icon margin-left="16px" />
            </template>
            <template v-slot:item="slotProps">
              <div class="campaign-list__list__content">
                <div class="campaign-list__list__content__name">
                  {{ getUserExpressDetails(slotProps.item.userExpressId) }}
                </div>
                <div
                  class="campaign-list__list__content__date"
                  v-html="
                    $t('userexpress.list.campaign.userexpress', {
                      dateStart: date(slotProps.itemcreatedAt),
                      dateEnd: date(slotProps.itemexpiredAt),
                    })
                  "
                ></div>
                <div class="campaign-list__list__content__action">
                  <ui-button
                    class="campaign-list__list__content__action__button"
                    v-if="campaignActive(slotProps.item)"
                    button="primary"
                    variant="error"
                    :label="$t('userexpress.list.campaign.button.disabled')"
                    @click.stop="editCampaignStatut(slotProps.item.id, stringifiedSearchFilters)"
                  />
                  <span v-else>
                    {{ $t('userexpress.list.campaign.disabled') }}
                  </span>
                </div>
              </div>
              <span
                class="campaign-list__list__status backoffice-icons"
                :class="[`campaign-list__list__status--${slotProps.item.status}`]"
              >
                <template v-if="slotProps.item.status === '1'"> success </template>
                <template v-else>error</template>
              </span>
            </template>
          </ui-data-list>
        </div>
        <div class="campaign-list__table">
          <ui-data-table
            :columns="columns"
            :rows="campaign"
            :total-rows="totalRowsCampaign"
            :is-loading="isLoading || isUpdatingCampaign"
            :pagination="campaignPagination"
            :default-api-call-per-page="100"
            @loadItems="loadCampaign({ filters: stringifiedSearchFilters, ...$event }, false, true)"
            @onCellClick="cellClicked"
          >
            <template slot="data-table-row" slot-scope="props">
              <!-- <router-link
                class="campaign-list__table__link"
                :to="{
                  name: 'CampaignId',
                  params: { id: props.row['id'], currentTab: 'general' },
                }"
              /> -->
              <span v-if="props.column.field === 'userexpress'">
                <div class="campaign-list__table__user">
                  <span class="campaign-list__table__user__name">
                    {{ getUserExpressDetails(props.row['userExpressId']) }}
                  </span>
                  <span class="campaign-list__table__user__email">
                    {{ getUserExpressDetails(props.row['userExpressId'], 'email') }}
                  </span>
                </div>
              </span>
              <span v-if="props.column.field === 'period'">
                <div
                  v-html="
                    $t('userexpress.list.campaign.userexpress', {
                      dateStart: date(props.row['createdAt']),
                      dateEnd: date(props.row['expiredAt']),
                    })
                  "
                ></div>
              </span>
              <span v-if="props.column.field === 'locationTotal'">
                {{ props.row['locationTotal'] }}
              </span>
              <span v-if="props.column.field === 'locationToValidate'">
                {{ props.row['locationToValidate'] }}
              </span>
              <span v-if="props.column.field === 'status'">
                <span
                  class="campaign-list__list__status backoffice-icons"
                  :class="[`campaign-list__list__status--${props.row['status']}`]"
                >
                  <template v-if="props.row['status'] === '1'"> success </template>
                  <template v-else>error</template>
                </span>
              </span>
              <span v-if="props.column.field === 'action'">
                <ui-button
                  v-if="campaignActive(props.row)"
                  button="primary"
                  variant="success"
                  :label="$t('userexpress.list.campaign.button.disabled')"
                  class="account__dropdown__header__back"
                  @click.stop="editCampaignStatut(props.row['id'], stringifiedSearchFilters)"
                />
                <span v-else>
                  {{ $t('userexpress.list.campaign.disabled') }}
                </span>
              </span>
            </template>
          </ui-data-table>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import UiButton from '@/components/UI/Button.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import UserExpressListFilters from './ListFilters'
import { onScroll } from '@/mixins/scroll.mixin'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'UserExpressListCampaign',
  components: {
    UiDataTable,
    UiDataList,
    UiDataSearch,
    UserExpressListFilters,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
    UiButton,
  },
  mixins: [onScroll],
  data() {
    return {
      search: '',
      stringifiedSearchFilters: 'status:1',
    }
  },
  props: {
    campaign: {
      type: Array,
      required: true,
    },
    campaignMobile: {
      type: Array,
      required: true,
    },
    campaignPagination: {
      type: Object,
      required: true,
    },
    userExpress: {
      type: Array,
      required: true,
    },
    userExpressFilteredActive: {
      type: Array,
      required: true,
    },
    userExpressFilteredDeactivate: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdatingCampaign: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScrollingCampaign: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalRowsCampaign: {
      type: Number,
      required: false,
      default: 0,
    },
    noMoreDataCampaign: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    stringifiedSearchFilters() {
      this.$emit('loadItems', {
        tableParams: { filters: this.stringifiedSearchFilters },
        resetCampaign: true,
        updating: true,
        search: this.search,
      })
    },
    search() {
      this.$emit('loadItems', {
        tableParams: { filters: this.stringifiedSearchFilters },
        resetCampaign: true,
        updating: true,
        search: this.search,
      })
    },
    canRefreshData: {
      handler() {
        if (this.canRefreshData && !this.isScrollingCampaign && !this.noMoreDataCampaign) {
          this.isScrollingCampaign = true
        }
      },
    },
  },
  computed: {
    noData() {
      return this.isLoading || this.noMoreDataCampaign
    },
    columns() {
      return [
        {
          label: this.$t('userexpress.list.campaign.column.userexpress'),
          field: 'userexpress',
          sortable: false,
        },
        {
          label: this.$t('userexpress.list.campaign.column.period'),
          field: 'period',
          sortable: false,
        },
        {
          label: this.$t('userexpress.list.campaign.column.locationTotal'),
          field: 'locationTotal',
        },
        {
          label: this.$t('userexpress.list.campaign.column.locationToValidate'),
          field: 'locationToValidate',
        },
        {
          label: this.$t('userexpress.list.campaign.column.status'),
          field: 'status',
        },
        {
          label: this.$t('userexpress.list.campaign.column.action'),
          field: 'action',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    onSearch(value) {
      this.search = typeof value === 'number' ? value.toString() : value
    },
    onUpdateFilters(stringifiedFilters) {
      this.stringifiedSearchFilters = stringifiedFilters
    },
    date(date) {
      return formatedDate(date, 'DD/MM/YYYY', this.$i18n.locale)
    },
    campaignActive(campaign) {
      return campaign.status === '1' ? true : false
    },
    cellClicked(e) {
      this.$emit('cellClicked', {
        name: 'CampaignId',
        id: e.row.id,
        administratorId: e.row.administratorId,
      })
    },
    loadCampaign(tableParams, resetCampaign = false, updating = false) {
      this.$emit('loadItems', {
        tableParams,
        resetCampaign,
        updating,
      })
    },
    async editCampaignStatut(id, filters) {
      const payload = { id: id, data: { status: '0' } }
      this.$emit('editCampaignStatut', { payload, filters })
    },
    getUserExpressDetails(userExpressId, label) {
      let userExpressDetails = userExpressId
      this.userExpress.forEach(userExpress => {
        if (userExpress.id === userExpressId) {
          if (label === 'email') userExpressDetails = userExpress.email
          else userExpressDetails = `${userExpress.firstName} ${userExpress.lastName}`
        }
      })
      return userExpressDetails
    },
  },
}
</script>

<style lang="scss">
.campaign-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }
  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__content {
      flex: 1;
      &__name {
        text-transform: capitalize;
        font-weight: 600;
      }
      &__action {
        display: flex;
        justify-content: flex-start;
        margin-top: 5px;
      }
    }

    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1 {
        color: map-get($generic-color-variants, 'success');
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    &__user {
      display: flex;
      flex-direction: column;
      &__name {
        text-transform: capitalize;
        font-weight: 600;
      }
      &__email {
        @include text-ellipsis(1, 14px);

        margin-top: 3px;
      }
    }

    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1 {
        color: map-get($generic-color-variants, 'success');
      }
    }

    &__link {
      @include full-link;
    }
  }
}
</style>
