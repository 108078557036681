var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-grid',{attrs:{"full-height":true}},[_c('screen-card',{attrs:{"full-height":true},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ui-data-search',{ref:"fixedElement",attrs:{"element-fixed":_vm.elementFixed,"scroll-direction":_vm.scrollDirection,"is-mobile":_vm.isMobile,"is-tablet":_vm.isTablet,"is-loading":_vm.isLoading,"is-scrolling":_vm.isScrollingCampaign,"view":"campaign","with-filter":true},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"filterSlot",fn:function(){return [_c('user-express-list-filters',{attrs:{"user-express-filtered-active":_vm.userExpressFilteredActive,"user-express-filtered-deactivate":_vm.userExpressFilteredDeactivate,"user-express":_vm.userExpress},on:{"onUpdateFilters":_vm.onUpdateFilters,"onUpdateUserExpressFilter":_vm.onSearch}})]},proxy:true}])}),_c('div',{staticClass:"campaign-list__list"},[_c('ui-data-list',{attrs:{"items":_vm.campaignMobile,"is-loading":_vm.isLoading,"is-scrolling":_vm.isScrollingCampaign,"no-more-data":_vm.noMoreDataCampaign,"pagination":_vm.campaignPagination,"infinite-scroll":""},on:{"loadItems":function($event){return _vm.loadCampaign(
              Object.assign({}, {filters: _vm.stringifiedSearchFilters}, $event, {page: _vm.campaignPagination.page + 1}),
              false,
              true
            )},"click":function($event){return _vm.cellClicked({ row: { id: $event.id } })}},scopedSlots:_vm._u([{key:"skeleton",fn:function(){return [_c('div',{staticClass:"campaign-list__list__content"},[_c('skeleton-line',{attrs:{"height":"18px","width":"80%"}}),_c('skeleton-line',{attrs:{"height":"15px","width":"50%","margin-top":"3px"}})],1),_c('skeleton-icon',{attrs:{"margin-left":"16px"}})]},proxy:true},{key:"item",fn:function(slotProps){return [_c('div',{staticClass:"campaign-list__list__content"},[_c('div',{staticClass:"campaign-list__list__content__name"},[_vm._v(" "+_vm._s(_vm.getUserExpressDetails(slotProps.item.userExpressId))+" ")]),_c('div',{staticClass:"campaign-list__list__content__date",domProps:{"innerHTML":_vm._s(
                  _vm.$t('userexpress.list.campaign.userexpress', {
                    dateStart: _vm.date(slotProps.itemcreatedAt),
                    dateEnd: _vm.date(slotProps.itemexpiredAt),
                  })
                )}}),_c('div',{staticClass:"campaign-list__list__content__action"},[(_vm.campaignActive(slotProps.item))?_c('ui-button',{staticClass:"campaign-list__list__content__action__button",attrs:{"button":"primary","variant":"error","label":_vm.$t('userexpress.list.campaign.button.disabled')},on:{"click":function($event){$event.stopPropagation();return _vm.editCampaignStatut(slotProps.item.id, _vm.stringifiedSearchFilters)}}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t('userexpress.list.campaign.disabled'))+" ")])],1)]),_c('span',{staticClass:"campaign-list__list__status backoffice-icons",class:[("campaign-list__list__status--" + (slotProps.item.status))]},[(slotProps.item.status === '1')?[_vm._v(" success ")]:[_vm._v("error")]],2)]}}])})],1),_c('div',{staticClass:"campaign-list__table"},[_c('ui-data-table',{attrs:{"columns":_vm.columns,"rows":_vm.campaign,"total-rows":_vm.totalRowsCampaign,"is-loading":_vm.isLoading || _vm.isUpdatingCampaign,"pagination":_vm.campaignPagination,"default-api-call-per-page":100},on:{"loadItems":function($event){return _vm.loadCampaign(Object.assign({}, {filters: _vm.stringifiedSearchFilters}, $event), false, true)},"onCellClick":_vm.cellClicked},scopedSlots:_vm._u([{key:"data-table-row",fn:function(props){return [(props.column.field === 'userexpress')?_c('span',[_c('div',{staticClass:"campaign-list__table__user"},[_c('span',{staticClass:"campaign-list__table__user__name"},[_vm._v(" "+_vm._s(_vm.getUserExpressDetails(props.row['userExpressId']))+" ")]),_c('span',{staticClass:"campaign-list__table__user__email"},[_vm._v(" "+_vm._s(_vm.getUserExpressDetails(props.row['userExpressId'], 'email'))+" ")])])]):_vm._e(),(props.column.field === 'period')?_c('span',[_c('div',{domProps:{"innerHTML":_vm._s(
                  _vm.$t('userexpress.list.campaign.userexpress', {
                    dateStart: _vm.date(props.row['createdAt']),
                    dateEnd: _vm.date(props.row['expiredAt']),
                  })
                )}})]):_vm._e(),(props.column.field === 'locationTotal')?_c('span',[_vm._v(" "+_vm._s(props.row['locationTotal'])+" ")]):_vm._e(),(props.column.field === 'locationToValidate')?_c('span',[_vm._v(" "+_vm._s(props.row['locationToValidate'])+" ")]):_vm._e(),(props.column.field === 'status')?_c('span',[_c('span',{staticClass:"campaign-list__list__status backoffice-icons",class:[("campaign-list__list__status--" + (props.row['status']))]},[(props.row['status'] === '1')?[_vm._v(" success ")]:[_vm._v("error")]],2)]):_vm._e(),(props.column.field === 'action')?_c('span',[(_vm.campaignActive(props.row))?_c('ui-button',{staticClass:"account__dropdown__header__back",attrs:{"button":"primary","variant":"success","label":_vm.$t('userexpress.list.campaign.button.disabled')},on:{"click":function($event){$event.stopPropagation();return _vm.editCampaignStatut(props.row['id'], _vm.stringifiedSearchFilters)}}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t('userexpress.list.campaign.disabled'))+" ")])],1):_vm._e()]}}])})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }